import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private loginService: LoginService, private router: Router) { }

  ngOnInit() {
    /*
    Consumiendo el servicio sin pasar por una funcion
    this.loginService.login('peter@klaven', 'cityslicka').subscribe(
      res => {
        console.log(res);      
    });
    */
  }
  logIn(username: string, password: string, event: Event) {
    event.preventDefault(); // Avoid default action for the submit button of the login form

    // Calls service to login user to the api rest
    this.loginService.login(username, password).subscribe(

      res => {
       console.log(res);

      },
      error => {
        console.error(error);
        
      },

      () => this.navigate()
    );

  }

  navigate() {
    //this.router.navigateByUrl('/equipo');
    //this.router.navigateByUrl('/');
    //this.router.navigateByUrl('/inicio');
    this.router.navigateByUrl('/backend');
    //this.router.navigate(['/alunos',this.aluno.id,'editar']);
  }


}
