import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {
  }

  login(username:string, password:string) {
    //return this.http.post('https://reqres.in/api/login', {
    return this.http.post('https://api-aguitech.casidios.com/api_login.php?aguitech=backend', {
      email: username,
      password: password,     
    });     
  }
}
